import React from "react";
import "./PoupUpUpdate.css";

import QrcodeImage from "../img/qrcode.png";

const DonationsAnimated = () => {
  return (
    <>
      <div className="container">
        <div className="animated-border"></div>
        <div className="corner flex">
          <div className="content flex">
            <h4 className="flex pt-2 text-sm placeholder:text-zinc-500 text-white">
              Com a sua doação, você pode garantir a continuidade do site!
            </h4>
            <p className="text-sm placeholder:text-zinc-500 text-white">
              Agora você pode ajudar o site a se manter fazendo doações a partir
              de R$1,00 e contribuir com o projeto.
            </p>
            <div className="containerqrcod">
              <img src={QrcodeImage} className="qrcodeimage pt-2 pb-2" />
              <p className="pt-4">
                <a
                  className="bottomStyle"
                  href="https://livepix.gg/iscompatible"
                  target="_blank"
                >
                  Ajudar o site a se manter
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default DonationsAnimated;
