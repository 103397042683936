/** @format */

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import DonationsAnimated from "../../utils/DonationsAnimated";
import Slider from "../slider/slider";

import ScriptAnalytcs from "../../utils/ScriptGoogleAnalytcs";

export default function About() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <Navbar />

        <div className="aboutus container-expand-lg">
          <br />
          <h1>Sobre a "É compatível?"</h1>

          <h2>Qual é o objetivo?</h2>
          <ul>
            <p>
              Tornar o início do processo de escolha das peças para o PC mais
              fácil, reunindo em um único lugar todas as informações que
              precisamos sobre as peças que estamos escolhendo ou pensando em
              escolher, mas não sabemos se a placa-mãe será compatível ou
              suportará os componentes.
            </p>
          </ul>
          <div id="atContainer-48eb8ab585d819466eb785748b3cb67f"></div>

          <h2>Como surgiu?</h2>
          <ul>
            <p>
              Em 2020, quando eu (João Vitor) estava pesquisando sobre quais
              marcas e peças escolher, buscando o melhor custo-benefício
              possível, criei um grupo no WhatsApp chamado "PC Gamer barato"
              (que existe até hoje). O grupo logo ficou lotado com mais de 200
              pessoas. Percebi, então, a dificuldade que todos nós tínhamos, e
              começamos a nos ajudar, trocando dicas e experiências com base nas
              vivências de cada membro do grupo.
            </p>
          </ul>

          <h2>O que o site faz?</h2>
          <ul>
            <p>
              O site permite que você escolha as peças que está pensando em
              comprar e verifique a compatibilidade delas, seguindo uma escala
              de escolha: <strong>Marca → Processador → Placa-mãe → Memória RAM → SSD → Placa de vídeo → Fonte</strong>. Ao
              selecionar uma placa-mãe, ela automaticamente retorna os itens
              compatíveis com ela, de acordo com as informações fornecidas pelas
              fabricantes.
            </p>
          </ul>

          <h2>O site possui loja física?</h2>
          <ul>
            <p>
              Não, o site foi criado apenas para ajudar as pessoas, sem qualquer
              vínculo com marcas específicas.
            </p>
          </ul>

          <Slider />

          <DonationsAnimated />

          <Footer />
          <br />
        </div>
      </div>
    </>
  );
}
