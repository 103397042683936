/** @format */

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import amdvsintel from "../../img/amdvsintel.jpg";
import amdvsintelbench from "../../img/amdvsintelbench.jpg";
import amdvsintelbasic from "../../img/amdvsintelbasic.jpg";
import Slider from "../slider/slider";

import DonationsAnimated from "../../utils/DonationsAnimated";

import ScriptAnalytcs from "../../utils/ScriptGoogleAnalytcs";

export default function Tips() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter marginTopTips">
        <Navbar />

        <div className="tips container-expand-lg justify-content-center align-items-center">
          <h1 className="h1tips">
            <strong>Bem-vindo à área de dicas</strong>
          </h1>

          <p>
            Aqui, vou te passar algumas dicas de como pensar em uma configuração
            de PC.
          </p>

          <h2>Qual marca escolher?</h2>

          <br />

          <div className="imgtips">
            <img src={amdvsintel} alt="AMD VS INTEL" width={570} height={200} />
          </div>

          <br />
          <br />
          <p>
            O primeiro passo antes de começar a escolher as peças do PC é
            definir se o processador será AMD ou INTEL. Você pode estar se
            perguntando: "Como assim? Se eu não sei exatamente qual escolher,
            qual seria a 'melhor' opção?" E a verdade é que não existe uma opção
            "melhor". A escolha deve ser baseada no seu orçamento e nas suas
            necessidades, então vou te apresentar os pontos positivos de cada
            uma das marcas.
          </p>

          <h3>Longo Prazo</h3>
          <p>
            Se você está pensando em uma plataforma para futuros upgrades, eu
            recomendaria sem dúvidas a AMD. A compatibilidade da AMD em seu
            socket é bem maior do que a da INTEL. Por exemplo, uma placa-mãe
            B450 AORUS é compatível desde a linha Athlon até os novos
            processadores Ryzen 5000, enquanto as placas INTEL geralmente
            oferecem compatibilidade apenas para 2 gerações, como no caso da 9ª
            e 10ª geração.
          </p>
          <br />

          <h3>Preço</h3>
          <h5>Linha Intermediária: Ryzen 5 vs Core i5</h5>
          <p>
            Quando falamos de preço, a escolha pode ser bem complexa, pois os
            preços variam bastante. Vamos considerar o seguinte: uma
            configuração com <strong>B450M + Ryzen 5600x</strong> custa cerca de{" "}
            <strong>R$1.800</strong> no kit, utilizando os sites de "Monte seu
            PC" das lojas Terabyte e Pichau. Por outro lado, uma configuração
            INTEL com <strong>B6600M + Core i5 12400F</strong> custa cerca de{" "}
            <strong>R$2.438</strong>. A placa-mãe B660M, mais recente, é mais
            bem construída, o que justifica o preço mais elevado em comparação
            com a B450M, que varia entre R$550 e R$1.100 dependendo do modelo.
            Outro ponto importante é que o 12400F oferece um desempenho superior
            ao 5600x, embora a diferença seja pequena. Em jogos, ele fica de 2%
            a 5% à frente do Ryzen 5600x. Portanto, se você busca mais FPS,
            talvez a INTEL seja uma opção melhor, especialmente com uma RTX
            3060, por exemplo.
          </p>

          <br />
          <h6>
            Veja um comparativo no site:{" "}
            <a href="https://hitesti.com/pt/compare/cpu/amd-ryzen-5-5600x-vs-intel-core-i5-12400f">
              Hitesti
            </a>{" "}
            e tire suas próprias conclusões.
          </h6>

          <div className="imgtips">
            <img src={amdvsintelbench} alt="benchmark" />
          </div>

          <h6>
            Confira também este vídeo do "Tiozão" fazendo um comparativo de
            desempenho entre os dois processadores.
          </h6>
          <iframe
            className="yotubetips"
            src="https://www.youtube.com/embed/pCNAclJyKb8?start=207"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <br />
          <br />
          <h5>Linha de "entrada": Ryzen 3 vs Core i3</h5>
          <p>
            Vamos comparar o Ryzen 3 4100 com o i3 10100F. O preço varia
            conforme a placa-mãe escolhida, mas para este exemplo, vamos focar
            no cenário mais econômico. O kit{" "}
            <strong>I3 10100F + H410M ou H510M</strong> custa entre{" "}
            <strong>R$979,80 e R$1.100</strong>, enquanto a configuração{" "}
            <strong>Ryzen 3 4100 + A320 ou A520</strong> (no caso da A320, será
            necessária uma atualização de BIOS) custa entre{" "}
            <strong>R$1.020,00 e R$1.100</strong>. Lembrando que ambos não
            possuem vídeo integrado, então você precisará comprar uma GPU
            separada.
          </p>

          <br />
          <h6>
            Veja um comparativo no site:{" "}
            <a href="https://technical.city/pt/cpu/Core-i3-10100F-vs-Ryzen-3-4100">
              Technical
            </a>
          </h6>

          <div className="imgtips">
            <img src={amdvsintelbasic} alt="AMD VS INTEL" />
          </div>

          <div id="atContainer-48eb8ab585d819466eb785748b3cb67f"></div>
          <h5>Linha com Gráfico Integrado</h5>
          <p>
            Agora, vamos falar sobre gráficos integrados. Quando se trata de
            APUs, a AMD se destaca no custo-benefício. A linha de APUs da AMD é
            mais madura do que a da INTEL. Por exemplo, a APU do Ryzen 5600G
            chega perto do desempenho de uma RX550 ou uma GTX 1050. Se você
            estiver considerando uma APU, confira este vídeo comparando o Ryzen
            5600G com o i5 12400 em jogos.
          </p>
          <br />

          <iframe
            className="yotubetips"
            src="https://www.youtube.com/embed/rgaYjXfidp8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <br />
          <br />
          <h4>Conclusão</h4>
          <p>
            Como você viu, existem muitos pontos a serem analisados ao escolher
            as peças do seu PC. As variáveis são diversas, e o que eu fiz aqui
            foi te dar alguns insights sobre como começar. Se o seu objetivo é
            edição de vídeos, por exemplo, os processadores Intel geralmente
            oferecem um desempenho um pouco superior (apesar de ser uma
            diferença pequena). Se você está buscando uma configuração
            econômica, e a configuração incluir uma GPU, minha recomendação é
            escolher o kit mais barato. Se a sua opção for por gráficos
            integrados (sem GPU), a AMD é uma excelente escolha, e, para futuros
            upgrades, também indico a AMD.
          </p>
          <br />

          <Slider />

          <br />

          <DonationsAnimated />

          <Footer />
        </div>
      </div>
      <br />
    </>
  );
}
