/** @format */

import React, { useState, useEffect } from "react";
import "./PoupUpUpdate.css";

const NewUpdatesAlert = ({ children }) => {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const seenAlert = localStorage.getItem("seenAlert");
    if (seenAlert) {
      setShowAlert(false);
    }
  }, []);

  const handleClose = () => {
    setShowAlert(false);
    localStorage.setItem("seenAlert", true);
  };

  return (
    <>
      <div style={{ paddingTop: "70px" }}>
        {showAlert && (
          <div className="container">
            <div className="animated-border"></div>
            <div className="corner flex">
              <div className="content flex">
                <div>
                  <h3 className="flex padding-top-h3 text-sm placeholder:text-zinc-500 text-white">
                    Novas atualizações adicionadas!
                  </h3>
                  <p className="flex text-sm placeholder:text-zinc-500 text-white"></p>

                  <ul>
                    <li className="text-sm placeholder:text-zinc-500 text-white">
                      Linha DDR5 adicionada:
                      <ul>
                        Foi adicionado a linha DDR5, agora você pode selecionar
                        a nova linha de processadores e placa-mãe compativeis
                        com memoria ram DDR5.
                      </ul>
                    </li>
                    <li className="text-sm placeholder:text-zinc-500 text-white">
                      Novas melhorias de verificação de compatibilidade.
                      <ul>
                        Foi aprimorado a verificação de compatibilidade de todos
                        os componentes, sendo mais preciso na verificação.
                      </ul>
                    </li>

                    <li className="text-sm placeholder:text-zinc-500 text-white">
                      Alterações de layout:
                      <ul>
                        Melhorias no Layout do site foram feitas, para melhorar
                        a experiência do usuário. Algumas delas foram: icones
                        dos componentes, botões de ações e seleção ainda mais
                        modernos.
                      </ul>
                    </li>

                    <li className="text-sm placeholder:text-zinc-500 text-white">
                      Alguns reforços:
                      <ul>
                        Caso verifique que existe uma incosistencia de
                        indicações das compatibilidades peço que me informem
                        para que eu possa verificar e corrigir. Por exemplo:
                        você selecionou um core i3 de 10gen e ele retornou que
                        consegue empurrar uma 4080 ti, isso seria uma
                        inconsistência pois ocorreria gargalo. nesse caso, entre
                        em nosso discord e informe.
                      </ul>
                    </li>
                    <button className="btnfooter" onClick={handleClose}>
                      OK
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewUpdatesAlert;
