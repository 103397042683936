import axios from "axios";
import { useEffect, useState } from "react";
import { headers } from "../../secure/auth/AuthTokenGetData";

import { UrlSite } from './UrlAccess';

export function UseFonteFetch(getGpuSelectedVoltage) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataFonte = async () => {
            try {
                if (getGpuSelectedVoltage) {
                    await axios.get(`${UrlSite}/node-api/fonte/data`, headers)
                        .then((res) => {
                            const filteredFontes = res.data.filter(
                                (fonte) => fonte.power >= getGpuSelectedVoltage
                            );
                            setData(filteredFontes);
                        });
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFonte();

    }, [getGpuSelectedVoltage]);

    return { data, loading, error };
}