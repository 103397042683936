/** @format */

import { useEffect, useState } from "react";

import Scripts from "../utils/Scripts";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "../pages/slider/slider";

import Qrcode from "../img/qrcode.png";

import Select from "react-select";
import { MdCancel, MdVerifiedUser } from "react-icons/md";
import DonationsAnimated from "../utils/DonationsAnimated";

import { SelectStyle } from "../utils/Styles/SelectStyleHome";

import { formStyles } from "../utils/Styles/FormStyleHome";
import { UseCpusFetch } from "../utils/api/CallCpusValues";
import { UseMemoryramFetch } from "../utils/api/CallMemoryramValues";
import { UseGpuFetch } from "../utils/api/CallGpuValues";
import { UseSsdFetch } from "../utils/api/CallSsdValues";
import { UseFonteFetch } from "../utils/api/CallFonteValues";
import { UseMotheboardsFetch } from "../utils/api/CallMotheboardsValues";
import { bottomConfirmCompatibility } from "../useCases/buttomComfirmCompatibility";
import { AlertTopHomeInfos } from "../utils/messages/AlertTopHome";

import NewUpdatesAlert from "../utils/NewsUpdated";

import ScriptAnalytcs from "../utils/ScriptGoogleAnalytcs";

import cpuLogo from "../img/icons/cpu.png";
import motheboardLogo from "../img/icons/motheboard.png";
import memoryLogo from "../img/icons/memory.png";
import ssdLogo from "../img/icons/ssd.png";
import gpuLogo from "../img/icons/gpu.png";
import fonteLogo from "../img/icons/fonte.png";
import pcLogo from "../img/icons/pc.png";
import obsLogo from "../img/icons/obs.png";
import ddrVersionLogo from "../img/icons/ddrversion.png";

export default function MyForm() {
  // Brand
  const BrandOptions = [
    { value: "", label: "" },
    { value: "amd", label: "AMD" },
    { value: "intel", label: "INTEL" },
  ];
  const [brandSelected, setBrandSelected] = useState();
  const getBrandSelectedOPtion = brandSelected
    ? brandSelected.value
    : undefined;

  const ddrVersion = [
    { value: "", label: "" },
    { value: "ddr4", label: "DDR4" },
    { value: "ddr5", label: "DDR5 " },
  ];
  const [ddrVersionSelected, setDdrVersionSelected] = useState();
  const getDdrSelectedOption = ddrVersionSelected
    ? ddrVersionSelected.value
    : undefined;

  // CPU
  const [cpuSelectedId, setCpuId] = useState("0");
  const getCpuSelectedId = cpuSelectedId.value;
  const {
    data: cpus,
    loadingCpu,
    errorCpu,
  } = UseCpusFetch(getBrandSelectedOPtion, getDdrSelectedOption);

  // MotheboardbrandSelected
  const [motheboardSelectedId, setMotheboardId] = useState("0");
  const getMotheboardSelectedId = motheboardSelectedId.value;
  const {
    data: motheboards,
    loadingMotheboard,
    errorMotheboard,
  } = UseMotheboardsFetch(getBrandSelectedOPtion, getCpuSelectedId);

  // Memoryram
  const [memoryselectedId, setMemoryId] = useState("0");
  const {
    data: Memoryram,
    loadingMemoryram,
    errorMemoryram,
  } = UseMemoryramFetch(getBrandSelectedOPtion, getMotheboardSelectedId);

  // SSD
  const [ssdselectedId, setSSDId] = useState("0");
  const {
    data: Ssd,
    loadingSsd,
    errorSsd,
  } = UseSsdFetch(getBrandSelectedOPtion, getMotheboardSelectedId);

  // GPU
  const [gpuselectedVoltage, setGPUVoltage] = useState("0");
  const getGpuSelectedVoltage = gpuselectedVoltage.voltage;
  const {
    data: Gpu,
    loadingGpu,
    errorGpu,
  } = UseGpuFetch(getBrandSelectedOPtion, getCpuSelectedId);

  // FONTE
  const [fonteSelected, setFonteSelected] = useState([]);
  const {
    data: Fonte,
    loadingFonte,
    errorFonte,
  } = UseFonteFetch(getGpuSelectedVoltage);

  // OTHERS
  const [isClicked, setIsClicked] = useState(false);
  const [isCompatible, setIsCompatible] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");

  // Get CpusAmd and set values on Select
  const [optionsCpu, setOptionsCpu] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingCpu && cpus.length > 0) {
      const newOptions = cpus.map((cpu) => ({
        value: cpu.id,
        label: cpu.cpuname,
        affiliate: cpu.affiliate_product_link,
      }));
      setOptionsCpu([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [cpus, loadingCpu]);

  if (errorCpu) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Get MotheboardAmd and set values on Select
  const [optionsMotheboard, setOptionsMotheboard] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingMotheboard && motheboards.length > 0) {
      const newOptions = motheboards.map((motheboard) => ({
        value: motheboard.motheboard_id,
        label: motheboard.motheboard,
        affiliate: motheboard.affiliate_product_link,
      }));
      setOptionsMotheboard([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [motheboards, loadingMotheboard]);

  if (errorMotheboard) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Get Memoryram and set values on Select (AMD/INTEL)
  const [optionsMemory, setOptionsmemoryram] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingMemoryram && Memoryram.length > 0) {
      const newOptions = Memoryram.map((memoryram) => ({
        value: memoryram.memoryram_id,
        label: memoryram.namememoryram,
        affiliate: memoryram.affiliate_product_link,
      }));
      setOptionsmemoryram([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [Memoryram, loadingMemoryram]);

  if (errorMemoryram) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Get Gpu and set values on Select (AMD/INTEL)
  const [optionsGPU, setOptionsGpu] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingGpu && Gpu.length > 0) {
      const newOptions = Gpu.map((gpu_name) => ({
        value: gpu_name.id,
        label: gpu_name.namegpu,
        voltage: gpu_name.operatingvoltage,
        affiliate: gpu_name.affiliate_product_link,
      }));
      setOptionsGpu([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [Gpu, loadingGpu]);

  if (errorGpu) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Get Ssd and set values on Select (AMD/INTEL)
  const [optionsSSD, setOptionsSsd] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingSsd && Ssd.length > 0) {
      const newOptions = Ssd.map((ssd_name) => ({
        value: ssd_name.ssd_id,
        label: ssd_name.namessd,
        affiliate: ssd_name.affiliate_product_link,
      }));
      setOptionsSsd([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [Ssd, loadingSsd]);

  if (errorSsd) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Get Fonte and set values on Select (AMD/INTEL)
  const [optionsFonte, setOptionsFonte] = useState([
    { value: "", label: "", affiliate: undefined },
  ]);
  useEffect(() => {
    if (!loadingFonte && Fonte.length > 0) {
      const newOptions = Fonte.map((fonte) => ({
        id: fonte.id,
        value: fonte.power,
        label: fonte.namefonte,
        affiliate: fonte.affiliate_product_link,
      }));
      setOptionsFonte([
        { value: "", label: "", affiliate: undefined },
        ...newOptions,
      ]);
    }
  }, [Fonte, loadingFonte]);

  if (errorFonte) {
    setMessage("Erro ao buscar dados, tente novamente mais tarde.");
  }

  // Reset all values when change brand
  useEffect(() => {
    if (brandSelected && brandSelected.value !== "") {
      setCpuId("0");
      setMotheboardId("0");
      setMemoryId("0");
      setSSDId("0");
      setGPUVoltage("0");
      setFonteSelected([]);

      setOptionsCpu([{ value: "", label: "", affiliate: undefined }]);
      setOptionsMotheboard([{ value: "", label: "", affiliate: undefined }]);
      setOptionsmemoryram([{ value: "", label: "", affiliate: undefined }]);
      setOptionsSsd([{ value: "", label: "", affiliate: undefined }]);
      setOptionsGpu([{ value: "", label: "", affiliate: undefined }]);
      setOptionsFonte([{ value: "", label: "", affiliate: undefined }]);

      setIsCompatible(false);
      setMessage("");
      setMessage2("");
    }
  }, [brandSelected]);

  const handleConfirmCompatibility = (e) => {
    e.preventDefault();

    bottomConfirmCompatibility(
      brandSelected,
      motheboardSelectedId,
      cpuSelectedId,
      memoryselectedId,
      ssdselectedId,
      gpuselectedVoltage,
      fonteSelected,
      setMessage,
      setMessage2,
      setIsCompatible,
      setIsClicked
    );
  };

  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <Scripts />
        <Navbar />
        <NewUpdatesAlert />
        {/* <AlertTopHomeInfos /> */}
        <div className="container-expand-lg containervar divform">
          <form
            className="formpricipal py text-sm placeholder:text-zinc-500"
            style={formStyles}>
            <div>
              <div className="d-flex align-items-center">
                <img src={obsLogo} width={45} height={45} />
                <h3 className="form-floating h1form">
                  Verificador de compatibilidade
                </h3>
              </div>

              <hr />

              <div className="d-flex align-items-center">
                <img src={pcLogo} width={30} />
                <label className="form-floating label px-1">Marca</label>
              </div>
              <Select
                options={BrandOptions}
                value={brandSelected}
                onChange={(selectedOption) => setBrandSelected(selectedOption)}
                placeholder="Selecione a marca"
                styles={SelectStyle}
              />

              <div className="d-flex align-items-center">
                <img src={ddrVersionLogo} width={30} />
                <label className="form-floating label px-1">
                  Tipo de Memória
                </label>
              </div>
              <Select
                options={ddrVersion}
                value={ddrVersionSelected}
                onChange={(selectedOption) =>
                  setDdrVersionSelected(selectedOption)
                }
                placeholder="Selecione a versão DDR"
                styles={SelectStyle}
              />

              <div className="d-flex align-items-center pt-1">
                <img src={cpuLogo} width={30} />
                <label className="form-floating label px-1">Processador</label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsCpu}
                  value={cpuSelectedId}
                  onChange={setCpuId}
                  placeholder="Selecione o processador"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {cpuSelectedId?.affiliate &&
                  cpuSelectedId?.affiliate !== "undefined" && (
                    <a
                      href={cpuSelectedId.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>

              <div className="d-flex align-items-center pt-1">
                <img src={motheboardLogo} width={30} />
                <label className="form-floating label px-1">Placa-mãe</label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsMotheboard}
                  value={motheboardSelectedId}
                  onChange={setMotheboardId}
                  placeholder="Selecione a placa-mãe"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {motheboardSelectedId?.affiliate &&
                  motheboardSelectedId?.affiliate !== "undefined" && (
                    <a
                      href={motheboardSelectedId.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>

              <div className="d-flex align-items-center pt-1">
                <img src={memoryLogo} width={30} />
                <label className="form-floating label px-1">Memória Ram</label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsMemory}
                  value={memoryselectedId}
                  onChange={setMemoryId}
                  placeholder="Selecione a memória RAM"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {memoryselectedId?.affiliate &&
                  memoryselectedId?.affiliate !== "undefined" && (
                    <a
                      href={memoryselectedId.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>

              <div className="d-flex align-items-center pt-1">
                <img src={ssdLogo} width={30} />
                <label className="form-floating label px-1">SSD</label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsSSD}
                  value={ssdselectedId}
                  onChange={setSSDId}
                  placeholder="Selecione o SSD"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {ssdselectedId?.affiliate &&
                  ssdselectedId?.affiliate !== "undefined" && (
                    <a
                      href={ssdselectedId.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>

              <div className="d-flex align-items-center pt-1">
                <img src={gpuLogo} width={30} />
                <label className="form-floating label px-1">
                  Placa de vídeo
                </label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsGPU}
                  value={gpuselectedVoltage}
                  onChange={setGPUVoltage}
                  placeholder="Selecione a placa de vídeo"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {gpuselectedVoltage?.affiliate &&
                  gpuselectedVoltage?.affiliate !== "undefined" && (
                    <a
                      href={gpuselectedVoltage.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>

              <div className="d-flex align-items-center pt-1">
                <img src={fonteLogo} width={30} />
                <label className="form-floating label px-1">Fonte</label>
              </div>
              <div className="d-flex align-items-center">
                <Select
                  options={optionsFonte}
                  value={fonteSelected}
                  onChange={setFonteSelected}
                  placeholder="Selecione a fonte"
                  styles={SelectStyle}
                  className="select-with-button"
                />
                {fonteSelected?.affiliate &&
                  fonteSelected?.affiliate !== "undefined" && (
                    <a
                      href={fonteSelected.affiliate}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-affiliate">
                      Comprar
                    </a>
                  )}
              </div>
              <button
                className="bottomStyle"
                onClick={handleConfirmCompatibility}>
                Verificar compatibilidade
              </button>
            </div>

            {isClicked && (
              <>
                <p
                  id="msg"
                  style={{
                    color: isCompatible ? "green" : "red",
                  }}>
                  {isCompatible ? <MdVerifiedUser /> :  <MdCancel />}
                  {message} {message2}
                </p>
                <p
                  id="tips"
                  className="text-sm placeholder:text-zinc-500 text-white">
                  <strong>Dicas:</strong> Sempre opte por uma fonte de energia
                  com potência um pouco superior à recomendação da fabricante,
                  cerca de 100W a mais. Ao escolher uma GPU da linha RTX 4000,
                  verifique se a fonte de energia escolhida possui três entradas
                  de 8 pinos, antes de efetuar a compra nas lojas de
                  informaticas.
                </p>

                <p className="text-sm placeholder:text-zinc-500 text-white">
                  <strong>OBS:</strong> nem todas as placa-mãe são recomendadas
                  usar os processadores indicados, pois força demais a placa-mãe
                  e ela não usufrui do processador como deveria, além de algumas
                  versões precisarem de atualização na BIOS, apesar das
                  fabricantes indicarem compatibilidade. Em caso de duvidas
                  acesse o grupo do
                  <a
                    href="https://discord.gg/Me9cn5pyEQ"
                    target="_blank"
                    rel="noreferrer">
                    <strong>
                      <i> Discord</i>
                    </strong>
                  </a>
                </p>

                <h6 className="text-sm placeholder:text-zinc-500 text-white">
                  Faça uma doação e ajude o site a se manter clicando{" "}
                  <a
                    href="https://livepix.gg/iscompatible"
                    target="_blank"
                    rel="noreferrer">
                    aqui.
                  </a>
                </h6>
                <img src={Qrcode} className="pt-2 pb-2" alt="QRCode" />
              </>
            )}

            <div>
              <p id="tips" className="text-sm placeholder:text-zinc-500"></p>
            </div>
          </form>

          <Slider />

          <DonationsAnimated />

          <br />

          <Footer className="pb-4" />
        </div>
        <br /> <br />
      </div>
    </>
  );
}
