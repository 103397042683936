export const SelectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "#00CED1",
        backgroundColor: "#00000",
        borderWidth: "2px",
        borderStyle: "solid",
        borderImageSource: state.isFocused
            ? "linear-gradient(45deg, #00CED1, #FF1493, #8A2BE2, #00CED1)"
            : "none",
        borderImageSlice: 1,
        animation: state.isFocused ? "borderAnimation 5s linear infinite" : "none",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#000000",
        opacity: 0,
        transform: "translateY(-10px)",
        animation: "zoomIn 0.3s ease-in-out forwards",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? "#000000" : undefined,
        color: "#00CED1",
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: "#1A1A1E",
        },
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "#C0C0C0",
    }),
};
