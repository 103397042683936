import axios from "axios";
import { UrlSite } from "./UrlAccess";
import { headers } from "../../secure/auth/AuthTokenGetData";

export async function fetchValidCompatibilities(brandSelected,
    motheboardId,
    cpuId,
    memoryramId,
    ssdId,
    GpuId,
    fonteId) {
    try {
        const res = await axios.post(
            `${UrlSite}/node-api/${brandSelected}/valid/relation`,
            {
                motheboardId,
                cpuId,
                memoryramId,
                ssdId,
                GpuId,
                fonteId
            }, headers
        );

        return { data: res.data, error: null };
    } catch (err) {
        return { data: null, error: err };
    }
}
