/** @format */

import React, { useState, useEffect } from "react";
import "./UpdatePage.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const UpdatePage = () => {
  const [count, setCount] = useState(3 * 86400);

  useEffect(() => {
    const endDate = new Date("2025-03-24T19:00:00");
    const interval = setInterval(() => {
      const now = new Date();
      setCount(Math.floor((endDate - now) / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (count < 0) {
    return null;
  }

  const days = Math.floor(count / 86400);
  const hours = Math.floor((count % 86400) / 3600);
  const minutes = Math.floor((count % 3600) / 60);
  const seconds = count % 60;

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT"
        crossOrigin="anonymous"
      />
      <Navbar />
      <div className="d-flex justify-content-center align-items-center paddingTop">
        <div className="text-center">
          <h1 className="text-primary title">Atualização em andamento</h1>
          <p className="lead text-secondary">
            Estamos trabalhando para tornar sua experiência conosco ainda
            melhor. A atualização pode conter novas funcionalidades, melhorias e
            correções de bugs. Durante esse período, o site pode ficar
            indisponível. Agradecemos sua compreensão. Até logo!
          </p>
          <p>
            <strong className="text-info">
              Data: 21/03/2025 a 24/03/2025 - Hora: 19 horas
            </strong>
          </p>
          <p>
            <strong className="countdown">
              Tempo restante previsto: {days} dias {hours}:
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </strong>
          </p>
          <p className="text-secondary">
            Agradecemos sua paciência enquanto trabalhamos para melhorar nosso
            serviço.
          </p>
        </div>
      </div>

      <style>
        {`
            .countdown {
              animation: countdown 1s ease-in-out infinite, textAnimation 5s linear infinite;
              font-size: 2rem;
              font-weight: bold;
              background-clip: text;
              -webkit-background-clip: text;
              color: transparent;
              display: inline-block;
              text-align: center;
              text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            }

            .title {
              font-size: 2.5rem;
              font-weight: 600;
              color: #1e90ff;
              text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
            }

            .lead {
              font-size: 1.2rem;
              color: #20b2aa;
              line-height: 1.6;
            }

            @keyframes countdown {
              0% {
                opacity: 1;
              }
              50% {
                opacity: 0.7;
              }
              100% {
                opacity: 1;
              }
            }

            @keyframes textAnimation {
              0% {
                background-image: linear-gradient(0deg, #00ced1, #1e90ff, #20b2aa, #00ced1);
              }
              25% {
                background-image: linear-gradient(90deg, #00ced1, #1e90ff, #20b2aa, #00ced1);
              }
              50% {
                background-image: linear-gradient(180deg, #00ced1, #1e90ff, #20b2aa, #00ced1);
              }
              75% {
                background-image: linear-gradient(270deg, #00ced1, #1e90ff, #20b2aa, #00ced1);
              }
              100% {
                background-image: linear-gradient(360deg, #00ced1, #1e90ff, #20b2aa, #00ced1);
              }
            }
          `}
      </style>

      <div className="max-width-container-footer">
        <div className="d-flex justify-content-center align-items-center">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default UpdatePage;
