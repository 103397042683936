/** @format */

import Scripts from "../utils/Scripts";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "../pages/slider/slider";
import DonationsAnimated from "../utils/DonationsAnimated";

import ScriptAnalytcs from "../utils/ScriptGoogleAnalytcs";
import { AlertTopHomeInfos } from "../utils/messages/AlertTopHome";

export default function Informations() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4960559188017689"
          crossorigin="anonymous"></script>
        <script
          type="text/javascript"
          src="//pl18279723.highcpmrevenuenetwork.com/f5/7d/b6/f57db6b22a8bdd38d827b79e2248fcec.js"></script>

        <Scripts />

        <Navbar />

        <div className="divalerttop">
          {/* <AlertTopHomeInfos /> */}
          <br />
        </div>

        <div className="informationdiv container-expand-lg divform-informations">
          <h2>
            Informações sobre como funciona o uso do Verificador de
            compatibilidade
          </h2>

          <h3>Primeiro passo</h3>
          <ul>
            <p>
              Primeiramente, você irá definir a marca que deseja usar, seja ela{" "}
              <strong>
                <i>"INTEL"</i>
              </strong>{" "}
              ou{" "}
              <strong>
                <i>"AMD"</i>
              </strong>
              . Vale ressaltar que, em caso de dúvidas sobre qual marca
              escolher, você pode entrar em contato comigo através do Discord ou
              das redes sociais, que estão disponíveis no final do site.
            </p>
          </ul>

          <h3>Segundo passo</h3>
          <ul>
            <p>
              Agora, devemos escolher o processador que desejamos utilizar, seja
              ele da linha R5/R7 da AMD, por exemplo, ou dos processadores Core
              i5/i7 da INTEL. Reforço que, em caso de dúvidas sobre como
              escolher uma boa configuração, você pode me contatar pelas redes
              sociais ou acessar:{" "}
              <a href="/recommendations" target="">
                <strong>
                  <i>Recomendações de configuração</i>
                </strong>
              </a>
            </p>
          </ul>

          <h3>Terceiro passo</h3>
          <ul>
            <p>
              Agora chegou a hora de escolher a placa-mãe. Vale ressaltar que as
              informações foram retiradas das listas de compatibilidade
              fornecidas pelas próprias fabricantes de cada placa-mãe, como
              Gigabyte, ASUS, ASROCK, etc. Assim, você pode escolher a placa-mãe
              compatível com o processador, pois essas fabricantes garantem que
              suas placas-mãe são compatíveis com determinadas marcas e modelos
              de processadores. Contudo, nem todos os processadores são
              recomendados para todas as placas-mãe. Por exemplo: não é indicado
              usar um Ryzen 9 3900 em uma placa-mãe A320M.
            </p>
          </ul>

          <h3>Quarto passo</h3>
          <ul>
            <p>
              Escolhida a placa-mãe, seguimos para a escolha da memória RAM.
              Aqui, serão listadas todas as memórias RAM compatíveis com a
              placa-mãe e o processador. Portanto, você pode escolher a memória
              RAM sem preocupações, pois a placa-mãe possui suporte para todas
              as opções listadas pelos fabricantes.
            </p>
          </ul>

          <h3>Quinto passo</h3>
          <ul>
            <p>
              Agora, vamos escolher o nosso SSD. Não se preocupe, pois a lógica
              do site é retornar apenas os SSDs compatíveis. Assim, basta
              escolher um modelo para ilustrar a configuração.
            </p>
          </ul>

          <h3>Sexto passo</h3>
          <ul>
            <p>
              Nesta etapa, vamos escolher a placa de vídeo. É importante estar
              atento à escolha, pois, embora as fabricantes garantam a
              compatibilidade, podem ocorrer gargalos. Tentei minimizar ao
              máximo esse tipo de problema, mas é sempre bom verificar se a
              configuração realmente não causará gargalos.
            </p>
          </ul>

          <h3>Sétimo passo</h3>
          <ul>
            <p>
              No penúltimo passo, vamos escolher a fonte de alimentação. As
              fontes só serão exibidas caso você tenha selecionado uma placa de
              vídeo. Caso contrário, elas não aparecerão. Depois de selecionar a
              placa de vídeo, basta escolher uma das fontes retornadas, todas
              previamente verificadas.
            </p>
          </ul>

          <h3>Último passo</h3>
          <ul>
            <p>
              Após seguir todos os passos acima, finalmente podemos clicar no
              botão "Verificar compatibilidade". O sistema irá informar se a
              configuração é compatível e qual a fonte recomendada para ela.
              Caso alguma opção não tenha sido selecionada, o sistema irá
              alertá-lo para selecionar todas as opções antes de prosseguir.
            </p>
          </ul>

          {/* <video className='videoInformation' src="https://iscompatible-api.com.br/video-process" autoPlay controls></video> */}

          <Slider />

          <br />

          <DonationsAnimated />

          <Footer />
          <br />
          <br />
        </div>

        <br />
        <br />
      </div>
    </>
  );
}
