import { fetchValidCompatibilities } from "../utils/api/CallValidCompatibilities";

export async function bottomConfirmCompatibility(
    brandSelected,
    motheboardSelectedId,
    cpuSelectedId,
    memoryselectedId,
    ssdselectedId,
    gpuselected,
    fonteSelected,
    setMessage,
    setMessage2,
    setIsCompatible,
    setIsClicked
) {
    try {
        if (!brandSelected || cpuSelectedId === "0" || motheboardSelectedId === "0" || memoryselectedId === "0") {
            setMessage("Você precisa selecionar pelo menos: Marca → DDR → Processador → Placa-mãe → Memoria ram.");
            setIsCompatible(false);
            setIsClicked(true);
            return;
        }


        const response = await fetchValidCompatibilities(
            brandSelected.value,
            motheboardSelectedId.value,
            cpuSelectedId.value,
            memoryselectedId.value,
            ssdselectedId.value,
            gpuselected.value,
            fonteSelected.id
        );

        if (!response) {
            setMessage("Erro ao verificar compatibilidade, tente novamente.");
            setIsCompatible(false);
            setIsClicked(true);
            return;
        }

        let errorMessages = "";

        if (response && response.data.IsValidMotheboardCpuMemory === false) {
            errorMessages += "Processador, Placa-Mãe e Memória RAM não são recomendados. ";
        }

        if (response && response.data.IsValidMotheboardSsd === false) {
            errorMessages += "Placa-Mãe e SSD não são recomendados. ";
        }

        if (response && response.data.IsValidCpuGpu === false) {
            errorMessages += "Processador e Placa de Vídeo não são recomendados. ";
        }

        if (response && response.data.IsValidGpuFonte === false) {
            errorMessages += "Fonte de Alimentação insuficiente para essa configuração.";
        }

        if (errorMessages === "") {
            setMessage("Todos os componentes selecionados são compatível!");
            if (gpuselected.voltage) {
                setMessage2(`Fonte recomendada: ${gpuselected.voltage}W ou mais.`);
            } else {
                setMessage2("");
            }
            setIsCompatible(true);
        } else {
            setMessage(errorMessages.trim());
            setMessage2("");
            setIsCompatible(false);
        }

        setIsClicked(true);

    } catch (error) {
        setMessage("Erro ao verificar compatibilidade, tente novamente.");
        setIsCompatible(false);
        setIsClicked(true);
    }
}
