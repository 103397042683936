import axios from "axios";
import { useEffect, useState } from "react";
import { headers } from "../../secure/auth/AuthTokenGetData";

import { UrlSite } from './UrlAccess';

export function UseCpusFetch(brandOption, getDdrSelectedOption) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchDataCpu = async () => {
            try {
                if (brandOption && getDdrSelectedOption) {
                    const res = await axios.get(`${UrlSite}/node-api/${brandOption}/cpu/${getDdrSelectedOption}`, headers);
                    setData(res.data);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchDataCpu();
    }, [brandOption, getDdrSelectedOption]);

    return { data, loading, error };
}