import axios from "axios";
import { useEffect, useState } from "react";
import { headers } from "../../secure/auth/AuthTokenGetData";
import { UrlSite } from "./UrlAccess";

export function UseSsdFetch(brandSelected, MotheboardId) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataSsd = async () => {
            try {
                if (brandSelected && MotheboardId) {
                    const res = await axios.get(`${UrlSite}/node-api/${brandSelected}/ssdcompatibles/${MotheboardId}`, headers);
                    setData(res.data);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDataSsd();

    }, [brandSelected, MotheboardId]);

    return { data, loading, error };
}